import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ErrorComponent from 'components/shared/ErrorComponent';
import { Loader } from 'components/shared/Loader';
import { Paginator } from 'components/shared/Paginator';

import { lower } from 'utils/StringUtils';

import axiosNotifications from 'config/axiosNotifications';
import { fetchClientText } from 'store/Client/ClientAnalyser/features';
import { setNotificationsToAccessed } from 'store/Client/ClientNotification';
import {
  fetchClientOrderDisplay,
  fetchClientTextOrderDisplay,
} from 'store/Client/OrderDisplay/features';
import { NOTIFICATION_TYPE } from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import SearchIcon from 'icons/SearchIcon';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';
import {
  setClientOrderId,
  setTextType as clientSetTextType,
  setOrderType as clientSetOrderType,
  setWriterCommandId,
} from 'store/Client/ClientAnalyser';

const pageNbOptions = [10, 15, 20, 50, 100];
const columns = [
  {
    label: 'Titre de la commande',
    field: 'commandTitle',
    sortable: false,
  },

  {
    label: 'Titre du texte',
    field: 'textTitle',
    sortable: false,
  },
  {
    label: '',
    field: 'notificationIndicator',
    sortable: false,
  },
];

function NotificationDetails({ notifications, title, isLoading, isError }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [allNotifications, setAllNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationPerPage, setNotificationPerPage] = useState(10);
  const [currentNotification, setCurrentNotification] = useState([]);
  const [filteredNotification, setFilteredNotification] = useState([]);

  const [pageNumbers, setPageNumbers] = useState([]);

  const [titleSearch, setTitleSearch] = useHistoryState('titleSearch', '');

  useEffect(() => {
    document.title = 'Typix - Mon tableau de bord';
    window.dispatchEvent(new Event('locationchange'));
  }, []);

  useEffect(() => {
    if (!notifications) return;
    const reversedArray = [...notifications].reverse();
    setAllNotifications(reversedArray);
  }, [notifications]);

  useEffect(() => {
    if (!notifications) return;
    if (titleSearch && titleSearch.length > 0) {
      setCurrentPage(1);
      setFilteredNotification(
        allNotifications.filter((x) => {
          return lower(x.commandTitle).includes(lower(titleSearch));
        })
      );
    } else {
      setFilteredNotification([...allNotifications]);
    }
  }, [titleSearch, allNotifications, notifications]);

  useEffect(() => {
    const indexOfLastOrder = currentPage * notificationPerPage;
    const indexOfFirstOrder = indexOfLastOrder - notificationPerPage;
    setCurrentNotification(
      filteredNotification.slice(indexOfFirstOrder, indexOfLastOrder)
    );
    setPageNumbers(
      [
        ...Array(
          Math.ceil(filteredNotification.length / notificationPerPage)
        ).keys(),
      ].map((i) => i + 1)
    );
  }, [currentPage, filteredNotification, notificationPerPage]);

  useEffect(() => {
    if (
      pageNumbers.length > 0 &&
      pageNumbers[pageNumbers.length - 1] < currentPage
    )
      setCurrentPage(pageNumbers[pageNumbers.length - 1]);
  }, [currentPage, pageNumbers]);

  function getTextColor(notificationType, isNotificationAccessed) {
    if (isNotificationAccessed) {
      return 'text-gray';
    } else if (notificationType === NOTIFICATION_TYPE.REVISION_DONE) {
      return 'text-orange';
    } else if (notificationType === NOTIFICATION_TYPE.COMMAND_READY) {
      return 'text-green';
    }
  }

  function redirect(
    notificationType,
    textId,
    orderId,
    writerCommandId,
    texteType,
    orderType
  ) {
    if (notificationType === NOTIFICATION_TYPE.COMMAND_READY) {
      dispatch(fetchClientOrderDisplay(orderId));
      dispatch(fetchClientTextOrderDisplay(orderId));
      history.push('/Client/DetailsDeLaCommande');
    } else {
      dispatch(setClientOrderId(orderId));
      dispatch(setWriterCommandId(writerCommandId));
      dispatch(clientSetTextType(texteType));
      dispatch(clientSetOrderType(orderType));
      dispatch(fetchClientText(textId));
      history.push('/Client/TextDetails');
    }
  }

  function accessNotification(notificationId) {
    axiosNotifications
      .put(`/notification/${notificationId}/accessed`)
      .then((res) => {
        setNotificationsToAccessed(notificationId);
      })
      .catch((err) => {});
  }

  return (
    <div className="col-12 col-md-9">
      <div className="box-wrapper">
        <div className="box-list-header">
          <div className="box-title"> {title} </div>
          <div className="box-input-text">
            <div className="input-group input-cs w-100">
              <div className="input-group-text">
                <SearchIcon />
              </div>
              <input
                type="text"
                value={titleSearch}
                onChange={(event) => setTitleSearch(event.target.value)}
                className="form-control ps-0"
                placeholder={t('Rechercher')}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <ErrorComponent />
        ) : (
          <div className="table-responsive">
            <div>
              <table className="table-lists table-commandes ">
                <thead>
                  <tr>
                    {columns.map(({ label, field, sortable }) => (
                      <th
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          width:
                            field === 'commandTitle'
                              ? '60%'
                              : field === 'textTitle'
                              ? '30%'
                              : '',
                        }}
                        key={field}
                      >
                        {t(label)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentNotification.map((notification, index) => (
                    <tr key={index}>
                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          accessNotification(notification.id);

                          redirect(
                            notification.notificationType,
                            notification.textId,
                            notification.commandId,
                            notification.writerCommandId,
                            notification.textType,
                            notification.commandType
                          );
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{ width: '350px' }}
                            className={
                              notification.accessed
                                ? 'text-gray'
                                : notification.accessed &&
                                  notification.notificationType ===
                                    NOTIFICATION_TYPE.COMMAND_READY
                                ? 'text-green'
                                : ''
                            }
                          >
                            {notification.commandTitle}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="icon-notification-table--container">
                          <span
                            className={getTextColor(
                              notification.notificationType,
                              notification.accessed
                            )}
                          >
                            {notification.textTitle}
                          </span>
                        </div>
                      </td>
                      <td>
                        {notification.accessed ? (
                          ''
                        ) : (
                          <div className="icon-notification-wrap">
                            <span className="icon-notification-table"></span>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <span className="me-2">{t('Afficher')}</span>

          <div className="dropdown" style={{ width: '70px' }}>
            <button
              className="btn dropdown-toggle dropdown-custom w-100"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {notificationPerPage}
              <span>
                <ChevronDownIcon />
              </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {pageNbOptions
                .filter((nb) => nb !== notificationPerPage)
                .map((item, index) => (
                  <li key={index} onClick={() => setNotificationPerPage(item)}>
                    <button className="dropdown-item">{item}</button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div>
          <Paginator
            isLoading={isLoading}
            listLength={filteredNotification.length}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
            nbElementsPerPage={notificationPerPage}
            goToPage={(page) => setCurrentPage(page)}
            hideTotalPageCount={true}
          />
        </div>
      </div>
    </div>
  );
}

export default NotificationDetails;
